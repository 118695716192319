export default {
	messages: {
		ru: {
			'Юридически': 'Юридически',
			'Технически': 'Технически',
			'Выбирай проверку от': 'Выбирай проверку от',
			'Тільки перевірені джерела даних і': 'Только проверенные источники данных и',
			'необмежений час доступу': 'неограниченное время доступа',
			'до ваших звітів': 'к вашим отчетам',
		},
		uk: {
			'Юридически': 'Юридично',
			'Технически': 'Технічно',
			'Выбирай проверку от': 'Обирай перевірку від',
			'Тільки перевірені джерела даних і': 'Тільки перевірені джерела даних і',
			'необмежений час доступу': 'необмежений час доступу',
			'до ваших звітів': 'до ваших звітів',
		},
	}
};
