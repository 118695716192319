import { mapGetters, mapMutations } from 'vuex';
import companies from './companies';
import betweenDate from '../../../mixins/betweenDate';

export default {
  name: 'CheckingHistory',
  components: {
    'vue-checking': require('../_CheckingVin/index.vue').default,
    'vue-withoutVin': require('../_withoutVinStep/index.vue').default,
  },
  mixins: [betweenDate],
  data() {
    return {
      orderState: 'ordering',
      companies,
      isShow: false,
      logDataDefault: {
        action_block: 4,
      },
    };
  },
  computed: {
    ...mapGetters({
      advertisements: 'graphql/advertisements',
      nameCompanyUSAReport: 'others/autotest/nameCompanyUSAReport',
      vinChecksPrices: 'others/autotest/autotestVinCheckPrices',
    }),
    advertisement() {
      return this.advertisements(this.advertisementId);
    },
    currentCompanyData() {
      return this.companies[this.nameCompanyUSAReport];
    },
    prices() {
      return (this.vinChecksPrices && this.vinChecksPrices[this.currentCompanyData.priceKey]) || {
        price: 0,
        originPrice: 0,
        percentDiscount: 0,
      };
    },
    profit() {
      return Math.round(this.vinChecksPrices?.[this.currentCompanyData.priceKey]?.profit) || 0;
    },
    order() {
      return {
        name: `${this.$t('перевірку')} ${this.currentCompanyData.name}`,
        advertisementId: this.advertisementId,
        profit: this.profit,
        payload: {
          price: this.prices.price,
          diagnostic_type: this.currentCompanyData.diagnosticType,
          type: 'vin',
        },
      };
    },
    advertisementId() {
      return this.$route.params.advertisementId;
    },
  },
  methods: {
    ...mapMutations({
      autotestLoggerDefaultData: 'others/autotest/autotestLoggerDefaultData',
      autotestLoggerData: 'others/autotest/autotestLoggerData',
    }),
    handlerViewExample() {
      this.autotestLoggerData({ ...this.logDataDefault, click_action: 1 });
    },
    buyCheck() {
      this.orderState = 'checking';
      this.autotestLoggerData({
        ...this.logDataDefault,
        click_action: 2,
        request_type: 2,
        payment_summ: this.prices.price,
        initial_payment: this.profit,
      });
    },
  },
  mounted() {
    this.setTime(new Date('Wed Feb 01 2023 00:00:01 GMT+0200 (Eastern European Standard Time)'), new Date('Tue Feb 28 2023 23:59:59 GMT+0200 (Eastern European Standard Time)'));
    this.isShow = this.showBetweenDateTime && this.currentCompanyData.name === 'CARFAX';
    this.autotestLoggerDefaultData({
      ...(this.currentCompanyData?.loggerInfoBlock || {}),
      autotest_price_usa_verifications: this.prices.price || 0,
    });
    if (this.currentCompanyData?.loggerBlockType) this.logDataDefault.block_type = this.currentCompanyData.loggerBlockType;
  },
  i18n: require('./i18n').default,
};
