export default {
  messages: {
    uk: {
      'Виїзна перевірка авто у вашому місті': 'Виїзна перевірка авто у вашому місті',
      'Експерти AUTO.RIA виїдуть до авто, перевірять його і надішлють звіт з фото та відео': 'Експерти AUTO.RIA виїдуть до авто, перевірять його і надішлють звіт з фото та відео',
      Перевірити: 'Перевірити',
      Безкоштовно: 'Безкоштовно',
      серпня: 'серпня',
    },
    ru: {
      'Виїзна перевірка авто у вашому місті': 'Выездная проверка авто в вашем городе',
      'Експерти AUTO.RIA виїдуть до авто, перевірять його і надішлють звіт з фото та відео': 'Эксперты AUTO.RIA выедут в авто, проверят его и отправят отчет по фото и видео',
      Перевірити: 'Проверить',
      Безкоштовно: 'Бесплатно',
      серпня: 'августа',
    },
  },
};
