export default {
  messages: {
    uk: {
      'Виїзна перевірка від Центру перевірок AUTO.RIA': 'Виїзна перевірка від Центру перевірок AUTO.RIA',
      'Вид звіту': 'Вид звіту',
      Приклад: 'Приклад',
      звіту: 'звіту',
      'Всего к оплате': 'Всьго до сплати',
      'Заказать проверку': 'Замовити перевірку',
      Безкоштовно: 'Безкоштовно',
      'виїзну перевірку': 'виїзну перевірку',
    },
    ru: {
      'Виїзна перевірка від Центру перевірок AUTO.RIA': 'Выездная проверка Центра проверок AUTO.RIA',
      'Вид звіту': 'Вид отчета',
      Приклад: 'Пример',
      звіту: 'отчета',
      'Всього до сплати': 'Всего к оплате',
      'Замовити перевірку': 'Заказать проверку',
      Безкоштовно: 'Бесплатно',
      'виїзну перевірку': 'выездную проверку',
    },
  },
};
