import {mapMutations} from "vuex";

export default {
	i18n: require('./i18n').default,
	components: {
		'vue-offer': require('./Offer/index.vue').default,
		'vue-order-comment': require('../_OrderComment/index.vue').default,
		'vue-order': require('../_Order/index.vue').default,
		'vue-acknowledgment': require('../_Acknowledgment/index.vue').default,
	},
	props: ['advertisementId'],
	data() {
		return ({
			state: 'choosing',

			offer: false,
			orderPayload: false,
			user: false,
			comment: false,
			logDataDefault: {
				action_block: 7,
				block_type: 7,
			}
		});
	},
	computed: {
		price() {
			const {prices: {price = 300} = {}} = this;
			return price;
		},
		order() {
			return ({
				name: this.$t('юридическую проверку'),
				advertisementId: this.advertisementId,
				payload: {
					price: this.price,
					type: 'juristical'
				}
			});
		},
		ordersId() {
			const {orderResponse: {orders: {law: {id} = {}} = {}} = {}} = this;
			return id;
		}
	},
	methods: {
		...mapMutations({
			autotestLoggerData: 'others/autotest/autotestLoggerData',
		}),
		onOfferChoosed(payload) {
			this.offer = {...payload};
			this.state = 'commenting';
		},
		onCommented(payload = {}) {
			const {user, comment} = payload;
			this.user = user;
			this.comment = comment;
			this.state = 'ordering';
		},
		onOrdered(payload) {
			this.orderResponse = {...payload};

			const {
				orders: {
					law: {
						id: lawId = 0,
						billingOrderId: lawBillingOrderId = 0
					} = {},
				} = {}
			} = payload;

			this.autotestLoggerData({
				...this.logDataDefault,
				billing_order_id: lawBillingOrderId,
				order_spare_id: lawId,
				payment_summ: this.offer?.payload?.price || 0,
				request_type: 4
			});

			this.state = 'ordered';
			this.gaEvent('Сheck_Auto', 'Click_on_Button_order_lawyer_1_new', 'check_selection');
		},
	},
};
