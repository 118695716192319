export default {
	messages: {
		uk: {
			'Перевірка історії за звітами': 'Перевірка історії за звітами {company}',
			'Повна історія зареєстрованих у США й Канаді автомобілів: зафіксовані пробіги, пошкодження, кількість власників, історія сервісного обслуговування, обтяження та використання в таксі': 'Повна історія зареєстрованих у США й Канаді автомобілів: зафіксовані пробіги, пошкодження, кількість власників, історія сервісного обслуговування, обтяження та використання в таксі',
			'Вид звіту': 'Вид звіту',
			'Приклад': 'Приклад',
			'звіту': 'звіту',
			'Пошкодження': 'Пошкодження',
			'Кількість власників': 'Кількість власників',
			'Історія сервісного обслуговування': 'Історія сервісного обслуговування',
			'Обтяження та використання в таксі': 'Обтяження та використання в таксі',
			'Всього до сплати': 'Всього до сплати',
			'Замовити перевірку': 'Замовити перевірку',
			'перевірку': 'перевірку',
			'Зафіксовані пробіги': 'Зафіксовані пробіги',
			'Буде перевірено VIN-код, який продавець вказав на сторінці авто': 'Буде перевірено VIN-код, який продавець вказав на сторінці авто',
		},
		ru: {
			'Перевірка історії за звітами': 'Проверка истории по отчетам {company}',
			'Повна історія зареєстрованих у США й Канаді автомобілів: зафіксовані пробіги, пошкодження, кількість власників, історія сервісного обслуговування, обтяження та використання в таксі': 'Полная история зарегистрированных в США и Канаде автомобилей: зафиксированы пробеги, повреждения, количество владельцев, история сервисного обслуживания, обременения и использования в такси',
			'Вид звіту': 'Вид отчета',
			'Приклад': 'Пример',
			'звіту': 'отчета',
			'Пошкодження': 'Повреждение',
			'Кількість власників': 'Количество владельцев',
			'Історія сервісного обслуговування': 'История сервисного обслуживания',
			'Обтяження та використання в таксі': 'Обременение и использование в такси',
			'Всього до сплати': 'Всего к оплате',
			'Замовити перевірку': 'Заказать проверку',
			'перевірку': 'проверку',
			'Зафіксовані пробіги': 'Зафиксированные пробеги',
			'Буде перевірено VIN-код, який продавець вказав на сторінці авто': 'Будет проверен VIN-код, который продавец указал на странице авто',
		},
	}
};
