export default {
	messages: {
		ru: {
			'Проверка автомобиля': 'Проверка автомобиля',
		},
		uk: {
			'Проверка автомобиля': 'Перевірка автомобіля',
		},
	}
};
