export default {
  messages: {
    ru: {
      'Введите Ваш комментарий': 'Введите Ваш комментарий к заказу',
      'Я согласен': 'Я согласен(а) с',
      'пользовательским соглашением': 'пользовательским соглашением',
      'С вами свяжется менеджер': 'С вами свяжется менеджер сайта для подтверждения проверки',
      'Заказать проверку': 'Заказать проверку',
      Безкоштовно: 'Бесплатно',
    },
    uk: {
      'Введите Ваш комментарий': 'Введіть Ваш коментар до замовлення',
      'Я согласен': 'Я згоден(а) з',
      'пользовательским соглашением': 'угодою користувача',
      'С вами свяжется менеджер': 'З вами зв\'яжеться менеджер сайту для підтвердження перевірки',
      'Заказать проверку': 'Замовити перевірку',
      Безкоштовно: 'Безкоштовно',
    },
  },
};
