import {mapGetters} from 'vuex';

export default {
	i18n: require('./i18n').default,
	props: ['advertisementId'],
	computed: {
		...mapGetters({
			advertisements: 'graphql/advertisements',
		}),
		advertisement() {
			return this.advertisements(this.advertisementId) || {};
		},
		title() {
			const {brand: {name: brand = ''} = {}, model: {name: model = ''} = {}, version = '', year = ''} = this.advertisement;
			return `${brand} ${model} ${version} ${year}`;
		}
	},
};
