export default {
	messages: {
		ru: {
			'Специалисты МВД проверяют документы и автомобиль на подлинность': 'Специалисты МВД проверяют документы и автомобиль на подлинность',
			'Скачать': 'Скачать',
			'Получить': 'Получить',
			'отчет': 'отчет',
			'Заказать': 'Заказать',
			'проверку': 'проверку',
			'проверка': 'проверка',
			'пример отчета': 'пример отчета',
			'юридическую проверку': 'юридическую проверку',

			'Юридическая проверка от специалистов МВД': 'Юридическая проверка от специалистов МВД',
			'Проверка кузова и двигателя на соотвествие указанным в техническом паспорте': 'Проверка кузова и двигателя на соотвествие указанным в техническом паспорте',
			'Проверка паспорта авто на подлинность': 'Проверка паспорта авто на подлинность',
		},
		uk: {
			'Специалисты МВД проверяют документы и автомобиль на подлинность': 'Фахівці МВС перевіряють документи і автомобіль на справжність',
			'Скачать': 'Скачати',
			'Получить': 'Отримати',
			'отчет': 'звіт',
			'Заказать': 'Замовити',
			'проверку': 'перевірку',
			'проверка': 'перевірка',
			'пример отчета': 'приклад звіту',
			'юридическую проверку': 'юридичну перевірку',

			'Юридическая проверка от специалистов МВД': 'Юридична перевірка від фахівців МВС',
			'Проверка кузова и двигателя на соотвествие указанным в техническом паспорте': 'Перевірка кузова і двигуна на відповідність зазначеним у технічному паспорті',
			'Проверка паспорта авто на подлинность': 'Перевірка паспорта авто на достовірність',
		},
	}
};
