export default {
  messages: {
    uk: {
      'Перевірка історії продажів авто за кордоном': 'Перевірка історії продажів авто за кордоном',
      'За даними CEBIA перевіряємо пробіг, продажі на онлайн-платформах, сервісне обслуговування тощо': 'За даними CEBIA перевіряємо пробіг, продажі на онлайн-платформах, сервісне обслуговування тощо',
      'Інформація від партнерів': 'Інформація від партнерів',
      'Пробіг': 'Пробіг',
      'Викрадення транспортних засобів': 'Викрадення транспортних засобів',
      'Записи в лістингу': 'Записи в лістингу',
      'Історія пошкоджень': 'Історія пошкоджень',
      'Історія обслуговування': 'Історія обслуговування',
      'Використовувався як таксі': 'Використовувався як таксі',
      'Ціна': 'Ціна',
      'Замовити перевірку': 'Замовити перевірку',
    },
    ru: {
      'Перевірка історії продажів авто за кордоном': 'Проверка истории продаж авто за границей',
      'За даними CEBIA перевіряємо пробіг, продажі на онлайн-платформах, сервісне обслуговування тощо': 'По данным CEBIA проверяем пробег, продажи на онлайн-платформах, сервисное обслуживание и т.п.',
      'Інформація від партнерів': 'Информация от партнеров',
      'Пробіг': 'Пробег',
      'Викрадення транспортних засобів': 'Угон транспортных средств',
      'Записи в лістингу': 'Записи в листинге',
      'Історія пошкоджень': 'История повреждений',
      'Історія обслуговування': 'История обслуживания',
      'Використовувався як таксі': 'Использовался как такси',
      'Ціна': 'Цена',
      'Замовити перевірку': 'Заказать проверку',
    },
  }
};
