import {mapActions, mapGetters} from 'vuex';

export default {
  i18n: require('./i18n').default,
  init({store, target}) {
    if (target === 'node') {
      return store.dispatch('others/autotest/manager').catch(console.error);
    }
  },
  computed: {
    ...mapGetters({
      manager: 'others/autotest/manager',
    }),
    ms() {
      return this.manager || {};
    },
    mPhoneArray() {
      return this.parsePhoneNumbers(this.ms.phone);
    }
  },
  methods: {
    ...mapActions({
      fetchManager: 'others/autotest/manager',
    }),
    parsePhoneNumbers(phoneString) {
      return phoneString.split(',').map(phoneNumber => ({ number: phoneNumber.trim() }));
    },
  },
  mounted() {
    this.fetchManager();
  },
};
