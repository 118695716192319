import {mapActions, mapGetters} from 'vuex';

const SEO_TARGET = 'check_selection';

export default {
	metaInfo() {
		const {title = '', keywords = '', description = ''} = this.getSeoData(SEO_TARGET) || {};

		return {
			title: title,
			meta: [
				{name: 'description', content: description},
				{name: 'keywords', content: keywords},
				{name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
			]
		};
	},
	computed: {
		...mapGetters({
			advertisements: 'graphql/advertisements',
			getSeoData: 'shared/seo/getTargetData'
		}),
	},
	methods: {
		...mapActions({
			fetchSeo: 'shared/seo/fetch'
		}),
		init() {
			return this.fetchSeo({_target: SEO_TARGET}).catch(console.error);
		}
	},
	serverPrefetch() {
		return this.init();
	},
	mounted() {
		this.init();
	}
};
