export default {
	messages: {
		ru: {
			'Оформление заявки на': 'Оформление заявки на',
			'здравствуйте': 'здравствуйте',
			'менеджер проекта "Проверенные авто"': 'менеджер проекта "Проверенные авто"',
			'Я начну готовить отчет': 'Я начну готовить отчет',
			'сразу после подтверджения оплаты': ' сразу после подтверджения оплаты',
			'как только вы подтвердите заказ': ', как только вы подтвердите заказ',
			'проверяя VIN-код': 'проверяя VIN-код по каждой из 15 баз',
			'Отчет о проверке будет доступен в Личном кабинете': 'Отчет о проверке будет доступен в Личном кабинете',
			'Ожидаю Вашей оплаты проверки': 'Ожидаю Вашей оплаты проверки',
			'Ожидаю вашего подтверждения': 'Ожидаю вашего подтверждения',
			'В случае отказа владельца, возможность бесплатной проверки возвращается заказчику услуги': 'В случае отказа владельца, возможность бесплатной проверки возвращается заказчику услуги',
			'До сплати': 'К оплате',
			'Я согласен': 'Я согласен(а) с',
			'пользовательским соглашением': 'пользовательским соглашением',
			'Оплатить отчет': 'Оплатить отчет',
			'Номер для связи': 'Номер для связи',
			'Оплата услуги проходит через сайт AUTO.RIA': 'Оплата услуги проходит через сайт AUTO.RIA',

			'В случае отказа владельца от проверки': 'В случае отказа владельца от проверки, оплаченные средства возвращаются заказчику услуги, за исключением 7% от стоимости заказанной проверки',

			'Необходимо подтвердить согласие с пользовательским соглашением': 'Необходимо подтвердить согласие с пользовательским соглашением',
			'корректный контактный номер телефона': 'Необходимо указать корректный контактный номер телефона',
			'заказ начнет обрабатываться после подтверждения оплаты': 'заказ начнет обрабатываться после подтверждения оплаты',

			'принята': 'принята',
			'главную': 'главную',
			'Личный кабинет': 'Личный кабинет',
			'Спасибо': 'Спасибо',
			'Результат проверки появится в Личном кабинете и Viber': 'Результат проверки появится в Личном кабинете и Viber',
			'Проверить': 'Проверить',
			'Перевірка по базах даних почнеться одразу після підтвердження оплати': 'Проверка по базам данных начнется сразу после подтверждения оплаты',
			'Звіт про перевірку буде доступний в особистому кабінеті': 'Отчет о проверке будет доступен в личном кабинете',
			'Буде перевірено VIN-код, який продавець вказав на сторінці авто': 'Будет проверен VIN-код, который продавец указал на странице авто',
			'Лишились питання й хочете дізнатись більше про перевірку': 'Остались вопросы и хотите узнать больше о проверке',
			'Дізнатись більше': 'Узнать больше',
		},
		uk: {
			'Оформление заявки на': 'Оформлення заявки на',
			'здравствуйте': 'вітаю',
			'менеджер проекта "Проверенные авто"': 'менеджер проекту "Перевірені авто"',
			'Я начну готовить отчет': 'Я почну готувати звіт',
			'сразу после подтверджения оплаты': ' відразу після підтвердження оплати',
			'как только вы подтвердите заказ': ', як тільки ви підтвердите замовлення',
			'проверяя VIN-код': 'перевіряючи VIN-код по кожній з 15 баз',
			'Отчет о проверке будет доступен в Личном кабинете': 'Звіт про перевірку буде доступний в Особистому кабінеті',
			'Ожидаю Вашей оплаты проверки': 'Очікую Вашої оплати перевірки',
			'Ожидаю вашего подтверждения': 'Очікую вашого підтвердження',
			'В случае отказа владельца, возможность бесплатной проверки возвращается заказчику услуги': 'У разі відмови власника, можливість безкоштовної перевірки повертається замовнику послуги',
			'До сплати': 'До сплати',
			'Я согласен': 'Я згоден(а) з',
			'пользовательским соглашением': 'угодою користувача',
			'Оплатить отчет': 'Оплатити звіт',
			'Номер для связи': 'Номер для зв\'язку',
			'Оплата услуги проходит через сайт AUTO.RIA': 'Оплата послуги проходить через сайт AUTO.RIA',

			'В случае отказа владельца от проверки': 'У разі відмови власника від перевірки, сплачені кошти повертаються замовнику послуги, за винятком 7% від вартості замовленої перевірки',

			'Необходимо подтвердить согласие с пользовательским соглашением': 'Необхідно підтвердити згоду з угодою користувача',
			'корректный контактный номер телефона': 'Необхідно вказати коректний контактний номер телефону',
			'заказ начнет обрабатываться после подтверждения оплаты': 'замовлення почне оброблятися після підтвердження оплати',

			'принята': 'прийнята',
			'главную': 'головну',
			'Личный кабинет': 'Особистий кабінет',
			'Спасибо': 'Дякую',
			'Результат проверки появится в Личном кабинете и Viber': 'Результат перевірки з\'явиться в Особистому кабінеті і Viber',
			'Проверить': 'Перевірити',
			'Перевірка по базах даних почнеться одразу після підтвердження оплати': 'Перевірка по базах даних почнеться одразу після підтвердження оплати',
		  	'Звіт про перевірку буде доступний в особистому кабінеті': 'Звіт про перевірку буде доступний в особистому кабінеті',
		  	'Буде перевірено VIN-код, який продавець вказав на сторінці авто': 'Буде перевірено VIN-код, який продавець вказав на сторінці авто',
		  	'Лишились питання й хочете дізнатись більше про перевірку': 'Лишились питання й хочете дізнатись більше про перевірку',
			'Дізнатись більше': 'Дізнатись більше',
		},
	}
};
