/* eslint-disable import/no-extraneous-dependencies,global-require,consistent-return */
import { mapGetters, mapMutations } from 'vuex';
import { autotestCreateOrder, autotestDatetime } from '../../../store/queries';

import openBillingWindow from '../../../helpers/openBillingWindow';
import { tel as telPattern } from '../../../../constants/patterns';

export default {
  i18n: require('./i18n').default,
  components: {
    'vue-login': require('../../Common/LoginForm/index.vue').default,
  },
  mixins: [require('../../../mixins/conversionPaidCheckGTM')],
  props: ['order', 'section', 'logDataDefault', 'questionName'],
  data() {
    return ({
      date: new Date(),
      agreed: true,
      errors: [],
      state: 'lastCheck',
    });
  },
  computed: {
    ...mapGetters({
      userData: 'Common/userData',
      manager: 'others/autotest/manager',
    }),
    telPatternString() {
      return telPattern.toString().split('/')[1];
    },
    userId() {
      const { userData: { userId } = {} } = this;
      return userId;
    },
    userName() {
      const { userData: { userName } = {} } = this;
      return userName;
    },
    userPhones() {
      return (this.userData.userPhones || []).join(',') || '';
    },
    ms() {
      return this.manager || {};
    },
    mName() {
      // Эту жесть не я придумал
      const key = `name_${this.langCode}`;
      return this.ms[key];
    },
    mPhoto() {
      return this.ms.photo;
    },
    isWorkTime() {
      const hours = this.date.getUTCHours();
      return hours >= 7 && hours < 16;
    },
    isWeekday() {
      const day = this.date.getDay();
      return day >= 1 && day <= 5;
    },
    isFriday() {
      return this.date.getDay() === 5;
    },
    orderPayload() {
      const { order: { payload } = {} } = this;
      if (payload) {
        const { type } = payload || {};
        return {
          [type]: payload,
        };
      }
    },
    orderPrice() {
      const { order: { payload: { price } = {} } = {} } = this;
      return price;
    },
    orderName() {
      const { order: { name } = {} } = this;
      return name;
    },
    orderProfit() {
      const { order: { profit = 0 } = {} } = this;
      return profit;
    },
  },
  methods: {
    ...mapMutations({
      autotestLoggerData: 'others/autotest/autotestLoggerData',
    }),
    getServiceTime() {
      return autotestDatetime()
        .then((response) => {
          this.date = new Date(response);
        });
    },
    onClickToPay() {
      this.errors.splice(0, this.errors.length);

      if (!this.agreed) {
        this.errors.push({ message: this.$t('Необходимо подтвердить согласие с пользовательским соглашением') });
      }
      if (!this.userPhones && !telPattern.test(this.contactPhone)) {
        this.errors.push({ message: this.$t('корректный контактный номер телефона') });
      }

      if (!this.errors.length && this.orderPayload) {
        this.state = 'waiting';
        this.gaEvent('Сheck_Auto', `Click_on_Button_order_${this.section}_2_new`, 'check_selection');

        const { order: { advertisementId } = {} } = this;

        this.autotestLoggerData({
          ...(this.logDataDefault || {}),
          click_action: 23,
          payment_summ: this.orderPrice || 0,
          initial_payment: this.orderProfit,
          request_type: 3,
        });

        this.sendOrderPriceGTM(this.orderPrice);

        const {
          userId: id = 0, userName: name = '', userEmail: email = '', userPhones: [phoneFromProfile] = [],
        } = this.userData || {};
        const phone = phoneFromProfile || this.contactPhone;
        const userInfo = {
          id, name, email, phone,
        };

        return autotestCreateOrder(advertisementId, userInfo, {}, this.orderPayload, '', id)
          .then((response = {}) => {
            if (response && response.status === 'ok') {
              this.state = 'confirmed';

              const {
                orders: {
                  vin: { id: vinId, billingOrderId: vinBillingOrderId } = {},
                } = {},
              } = response;

              this.autotestLoggerData({
                ...(this.logDataDefault || {}),
                billing_order_id: vinBillingOrderId,
                order_spare_id: vinId,
                payment_summ: this.orderPrice || 0,
                initial_payment: this.orderProfit,
                request_type: 4,
              });

              if (vinBillingOrderId && this.orderPrice) {
                openBillingWindow(vinBillingOrderId, this.$t('Оплатить отчет'));
              }
            }
          });
      }
    },
    clickAgree() {
      this.autotestLoggerData({ ...(this.logDataDefault || {}), click_action: 21 });
    },
    clickAgreeLink() {
      this.gaEvent('Сheck_Auto', 'Click_on_link_agreement_new', 'check_selection');
      this.autotestLoggerData({ ...(this.logDataDefault || {}), click_action: 22 });
    },
    clickCabinet() {
      this.autotestLoggerData({ ...(this.logDataDefault || {}), click_action: 31 });
    },
  },
  watch: {
    userId() {
      if (this.userId) {
        this.getServiceTime();
      }
    },
  },
  mounted() {
    if (this.userId) {
      this.getServiceTime();
    }
  },
};
