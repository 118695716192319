import _ from 'lodash';
import {mapGetters, mapMutations} from 'vuex';

export default {
	props: ['advertisementId'],
	computed: {
		...mapGetters({
			vinSvg: 'others/vin/vinSvg',
			advertisements: 'graphql/advertisements',
		}),
		advertisement() {
			return this.advertisements(this.advertisementId) || {};
		},
		vin() {
			return this.vinSvg(this.advertisementId) || '';
		},
		url() {
			const {uri = ''} = this.advertisement;
			return uri;
		},
		img() {
			const {brand: {eng: brand = ''} = {}, model: {eng: model = ''} = {}, photos: {main} = {}} = this.advertisement;
			const {id} = main || {};
			return id ? `https://cdn.riastatic.com/photosnew/auto/photo/${brand}_${model}__${id}m.jpg` : 'https://img6.auto.ria.com/images/nophoto/no-photo-620x495.jpg';
		},
		title() {
			const {brand: {name: brand = ''} = {}, model: {name: model = ''} = {}, version = '', year = ''} = this.advertisement;
			return `${brand} ${model} ${version} ${year}`;
		},
		VINSymbols() {
			const VIN = this.advertisement.VIN;
			return typeof VIN === 'string' ? VIN.split('') : [];
		},
	},
	methods: {
		...mapMutations({
			autotestLoggerData: 'others/autotest/autotestLoggerData',
		}),
		autoBlockLogger() {
			this.autotestLoggerData({ action_block: 2, click_action: 1 });
		},
	},
};
