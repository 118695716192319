export default {
	messages: {
		ru: {
			'Город проверки': 'Город проверки',
			'Моя проверка': 'Моя проверка',
			'Все включено': 'Все включено',
			'Выберите необходимый набор проверок': 'Выберите необходимый набор проверок',
			'Скачать': 'Скачать',
			'Получить': 'Получить',
			'отчет': 'отчет',
			'отчета': 'отчета',
			'Заказать': 'Заказать',
			'проверку': 'проверку',
			'пример отчета': 'пример отчета',
			'Всего к оплате:': 'Всего к оплате:',
			'техническую проверку': 'техническую проверку',
			'Пример': 'Пример',
			'Вид отчета': 'Вид отчета',
			'У місті продажу авто відсутні СТО-партнери': 'К сожалению, в городе продажи автомобиля отсутствуют СТО-партнеры AUTO.RIA. Просмотрите ближайшие СТО, мы предложим продавцу проверку на любой из них.',
			'Найближчі СТО': 'Ближайшие СТО',
			'и ёмкость батареи': 'и ёмкость батареи',

		},
		uk: {
			'Город проверки': 'Місто перевірки',
			'Моя проверка': 'Моя перевірка',
			'Все включено': 'Все включено',
			'Выберите необходимый набор проверок': 'Виберіть необхідний набір перевірок',
			'Скачать': 'Скачати',
			'Получить': 'Отримати',
			'отчет': 'звіт',
			'отчета': 'звіту',
			'Заказать': 'Замовити',
			'проверку': 'перевірку',
			'пример отчета': 'приклад звіту',
			'Всего к оплате:': 'Всього до сплати:',
			'техническую проверку': 'технічну перевірку',
			'Пример': 'Приклад',
			'Вид отчета': 'Вид звіту',
			'У місті продажу авто відсутні СТО-партнери': 'На жаль, у місті продажу авто відсутні СТО-партнери AUTO.RIA. Перегляньте найближчі СТО, ми запропонуємо продавцеві перевірку на будь-якій із них',
			'Найближчі СТО': 'Найближчі СТО',
			'и ёмкость батареи': 'та ємність батареї',
		},
	}
};
