import betweenDate from "../../../mixins/betweenDate";
import {mapMutations} from "vuex";

export default {
	i18n: require('./i18n').default,
	components: {
		'vue-offer': require('./Offer/index.vue').default,
		'vue-order-comment': require('../_OrderComment/index.vue').default,
		'vue-order': require('../_Order/index.vue').default,
		'vue-acknowledgment': require('../_Acknowledgment/index.vue').default,
	},
	mixins: [require('../../../mixins/conversionPaidCheckGTM'),betweenDate],
	props: {
		advertisementId: { type: String, required: true },
		techCheck3500: { type: Boolean, required: true },
		isElectric: { type: Boolean, required: true }
	},
	data() {
		return ({
			state: 'choosing',

			offer: false,
			orderPayload: false,
			user: false,
			comment: false,
			isShow: false,
			orderResponse: {},
			logDataDefault: {
				action_block: 6,
				block_type: 6,
			}
		});
	},
	computed: {
		ordersId() {
			const {orderResponse: {orders: {technical: {id} = {}} = {}} = {}} = this;
			return id;
		},
		blockTitle(){
			return this.isElectric
				? `${this.$t('Техническая проверка')} ${this.$t('электроавто')} ${this.$t('в Сертифицированном')}`
				: `${this.$t('Техническая проверка')} ${this.$t('в Сертифицированном')}`
		}
	},
	methods: {
		...mapMutations({
			autotestLoggerData: 'others/autotest/autotestLoggerData',
		}),
		onOfferChoosed(payload) {
			this.offer = {...payload};
			this.state = 'commenting';
		},
		onCommented(payload = {}) {
			const {user, comment} = payload;
			this.user = user;
			this.comment = comment;
			this.state = 'ordering';
			this.sendOrderPriceGTM(this.offer?.payload?.price || 0);
		},
		onOrdered(payload) {
			this.orderResponse = {...payload};

			const {
				orders: {
					technical: {
						id: techId = 0,
						billingOrderId: techBillingOrderId = 0
					} = {},
				} = {}
			} = payload;

			this.autotestLoggerData({
				...this.logDataDefault,
				billing_order_id: techBillingOrderId,
				order_spare_id: techId,
				payment_summ: this.offer?.payload?.price || 0,
				request_type: 4
			});

			this.state = 'ordered';
			this.gaEvent('Сheck_Auto', 'Click_on_Button_order_technical_1_new', 'check_selection');
		}
	},
	mounted() {
		this.setTime(new Date('Sat Dec 24 2022 00:00:01 GMT+0200 (Eastern European Standard Time)'), new Date('Sun Jan 08 2023 23:59:59 GMT+0200 (Eastern European Standard Time)'));
		this.isShow = this.showBetweenDateTime
	},
};
