import { mapGetters, mapMutations } from 'vuex';
import {
  example, exampleExtra, exampleCarfax, exampleAutoCheck,
} from './constants';
import betweenDate from '../../../mixins/betweenDate';

export default {
  i18n: require('./i18n').default,
  mixins: [betweenDate],
  name: 'CheckingVIN',
  components: {
    'vue-checking': require('../_CheckingVin/index.vue').default,
    'vue-withoutVin': require('../_withoutVinStep/index.vue').default,
  },
  init({ store, context }) {
    if (context) {
      return store.dispatch('others/autotest/autotestVinMaxBrands');
    }
  },
  props: ['advertisementId'],
  data() {
    return ({
      examplePlus: example,
      exampleMax: exampleExtra,
      exampleCarfax,
      exampleAutoCheck,
      state: 'ordering',
      logDataDefault: {
        action_block: 3,
      },
      questionName: '',
      isShow: false,
    });
  },
  computed: {
    ...mapGetters({
      userData: 'Common/userData',
      advertisements: 'graphql/advertisements',
      usaReportsExist: 'others/autotest/usaReportsExist',
      vinMaxBrands: 'others/autotest/autotestVinMaxBrands',
      vinChecksPrices: 'others/autotest/autotestVinCheckPrices',
    }),
    prices() {
      return {
        vinPlus: this.vinChecksPrices?.vinPlus?.price || 0,
        vinMax: this.vinChecksPrices?.vinMax?.price || 0,
      };
    },
    originPrices() {
      return {
        vinPlus: this.vinChecksPrices?.vinPlus?.originPrice || 0,
        vinMax: this.vinChecksPrices?.vinMax?.originPrice || 0,
      };
    },
    percentageProfit() {
      return {
        vinPlus: this.vinChecksPrices?.vinPlus?.percentDiscount || 0,
        vinMax: this.vinChecksPrices?.vinMax?.percentDiscount || 0,
      };
    },
    hasSomeDiscount() {
      return this.prices.vinMax < this.originPrices.vinMax || this.prices.vinPlus < this.originPrices.vinPlus;
    },
    advertisement() {
      return this.advertisements(this.advertisementId);
    },
    brandName() {
      const { brand: { name = '' } = {} } = this.advertisement || {};
      return name;
    },
    maxAvailable() {
      const {
        category: { id: category } = {},
        brand: { id: brand } = {},
        year,
      } = this.advertisement || {};
      const vinMaxIds = this.vinMaxBrands.map(({ id }) => id);
      return year > 1993 && (vinMaxIds.includes(Number(brand)) && [1, 2, 6].includes(Number(category)));
    },
    maxBrandsName() {
      const vinMaxNames = this.vinMaxBrands.map(({ name }) => ` ${name}`);
      return vinMaxNames.join(',');
    },
    reportsUSAAvailable() {
      const { autocheck, carfax, checkSum } = this.usaReportsExist || {};
      if (checkSum && (autocheck || carfax)) {
        return (carfax.status === 200 || autocheck.status === 200);
      }
      return false;
    },
    reportUSACompany() {
      const { autocheck, carfax, checkSum } = this.usaReportsExist;
      if (checkSum && (autocheck || carfax)) {
        if (carfax.status === 200) {
          return 'CARFAX';
        }
        if (autocheck.status === 200) {
          return 'AutoCheck';
        }
      } else {
        return undefined;
      }
    },
    order() {
      switch (this.type) {
        case 'Max':
          return {
            name: `${this.$t('проверку')} VIN Max`,
            advertisementId: this.advertisementId,
            profit: Math.round(this.vinChecksPrices?.vinMax?.profit) || 0,
            payload: {
              price: this.vinChecksPrices.vinMax.price,
              diagnostic_type: 2,
              type: 'vin',
            },
          };
        case 'Plus':
          return {
            name: `${this.$t('проверку')} VIN+`,
            advertisementId: this.advertisementId,
            profit: Math.round(this.vinChecksPrices?.vinPlus?.profit) || 0,
            payload: {
              price: this.vinChecksPrices.vinPlus.price,
              diagnostic_type: 1,
              type: 'vin',
            },
          };
      }
    },
  },
  methods: {
    ...mapMutations({
      autotestLoggerDefaultData: 'others/autotest/autotestLoggerDefaultData',
      autotestLoggerData: 'others/autotest/autotestLoggerData',
    }),
    autotestLogger(logData) {
      this.autotestLoggerData({ ...this.logDataDefault, ...logData });
    },
    onViewExample(type) {
      this.gaEvent('Сheck_Auto', 'Click_on_link_sample_report_vin_new', 'check_selection');

      switch (type) {
        case 'Plus':
          this.autotestLogger({ block_type: 1, click_action: 1 });
          break;
        case 'Max':
          this.autotestLogger({ block_type: 2, click_action: 1 });
          break;
      }
    },
    onOrder(type) {
      this.type = type;
      this.state = 'checking';
      this.gaEvent('Сheck_Auto', 'Click_on_Button_order_vin_1_new', 'check_selection');

      switch (type) {
        case 'Plus':
          this.logDataDefault.block_type = 1;
          this.autotestLogger({
            request_type: 2,
            click_action: 2,
            payment_summ: this.vinChecksPrices?.vinPlus?.price,
            initial_payment: Math.round(this.vinChecksPrices?.vinPlus?.profit) || 0,
          });
          this.questionName = 'VIN+';
          break;
        case 'Max':
          this.logDataDefault.block_type = 2;
          this.autotestLogger({
            request_type: 2,
            click_action: 2,
            payment_summ: this.vinChecksPrices?.vinMax?.price,
            initial_payment: Math.round(this.vinChecksPrices?.vinMax?.profit) || 0,
          });
          this.questionName = 'VIN MAX';
          break;
      }
    },
  },
  mounted() {
    this.autotestLoggerDefaultData({
      autotest_vin_plus_verification: 1,
      autotest_price_vin_plus_verification: this.vinChecksPrices?.vinPlus?.price || 0,
      autotest_vin_max_verification: this.maxAvailable ? 1 : 0,
      autotest_price_vin_max_verification: this.maxAvailable ? this.vinChecksPrices?.vinMax?.price : 0,
    });
    this.setTime(new Date('Nov Fri 24 2023 00:00:01 GMT+0200 (Eastern European Standard Time)'), new Date('Nov Fri 24 2023 23:59:59 GMT+0200 (Eastern European Standard Time)'));
    this.isShow = this.showBetweenDateTime;
  },
};
