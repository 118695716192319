export default {
	messages: {
		ru: {
			'с': 'с',
			'Вс': 'Вс',
			'common.aboutDetailsProgram2':'Узнайте о подробностях у менеджера проекта',
			'html.buAuto.vozniklivoprosu': 'Возникли вопросы',
			'Менеджер “Сервісу перевірки авто”': 'Менеджер "Сервиса проверки авто"',
		},
		uk: {
			'с': 'з',
			'Вс': 'Нд',
			'common.aboutDetailsProgram2':'Дізнайтесь про подробиці у менеджера проекту',
			'html.buAuto.vozniklivoprosu': 'Виникли запитання',
			'Менеджер “Сервісу перевірки авто”': 'Менеджер “Сервісу перевірки авто”',
		},
	}
};
