import translate from './i18n';

export default {
  data() {
    return ({
      isShow: false,
    })
  },
  i18n: {
    ...translate,
  },
  mounted() {
    if(new Date() < new Date('Sat Aug 31 2024 23:59:59 GMT+0300 (Eastern European Standard Time)')){
      this.isShow = true;
    }
  },
};
