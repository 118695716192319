import {mapGetters, mapMutations} from 'vuex';
import humanize from "../../../mixins/humanize";

export default {
	i18n: require('./i18n').default,
	components: {
		'vue-login': require('../../Common/LoginForm/index.vue').default,
	},
	props: ['id', 'price', 'eventLabel', 'logDataDefault'],
	mixins: [humanize],
	data() {
		return ({
			comment: '',
			phone: '',
			disabled: false,
		});
	},
	computed: {
		...mapGetters({
			userData: 'Common/userData'
		}),
		userId() {
			const {userData: {userId} = {}} = this;
			return userId;
		},
		isPhoneNeeded() {
			const {userPhones: [phoneFromProfile] = []} = this.userData || {};
			return !phoneFromProfile;
		},
		priceHumanize() {
			return this.humanizeNumber(this.price);
		},
	},
	methods: {
		...mapMutations({
			autotestLoggerData: 'others/autotest/autotestLoggerData',
		}),
		clickAgree() {
			this.autotestLoggerData({ ...(this.logDataDefault || {}), click_action: 21 });
		},
		clickAgreeLink() {
			this.gaEvent('Сheck_Auto','Click_on_link_agreement_new','check_selection');
			this.autotestLoggerData({ ...(this.logDataDefault || {}), click_action: 22 });
		},
		onSubmit(event) {
			event.preventDefault();
			const {comment} = this;
			const {userId: id = 0, userName: name = '', userEmail: email = '', userPhones: [phoneFromProfile] = []} = this.userData || {};

			const phone = this.phone || phoneFromProfile;
			const user = {id, name, email, phone};

			this.gaEvent('Сheck_Auto',`Click_on_Button_order_${this.eventLabel}_2_new`,'check_selection');
			this.autotestLoggerData({
				...(this.logDataDefault || {}),
				click_action: 23,
				payment_summ: this.price || 0,
				request_type: 3
			});

			this.$emit('commented', {user, comment});
		}
	}
};
