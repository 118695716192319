import {autotestCreateOrder as createOrder} from '../../../store/queries';

export default {
	props: ['payload'],
	mounted() {
		const {payload: {advertisementId, user, orderData, message} = {}} = this;

		return createOrder(advertisementId, user, {}, orderData, message, user.id)
			.then((response) => this.$emit('ordered', response))
			.catch((error) => this.$emit('error', error));
	}
};
