import {exampleAutoCheck, exampleCarfax} from "../VIN/constants.json";

// For logging new verification types contact please Analytic Team Verified Cars
export default {
    'CARFAX': {
        name: 'CARFAX',
        hrefExample: exampleCarfax,
        diagnosticType: 3,
        loggerBlockType: 3,
        loggerInfoBlock: { autotest_usa_verifications_carfax: 1 },
        priceKey: 'carfax'
    },
    'AutoCheck': {
        name: 'AutoCheck',
        hrefExample: exampleAutoCheck,
        diagnosticType: 4,
        loggerBlockType: 4,
        loggerInfoBlock: { autotest_usa_verifications_autocheck: 1 },
        priceKey: 'autocheck'
    }
}
