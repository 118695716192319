import store from '../../../../mixins/store';
import {autotestPriceLaw} from '../../../../store/queries';
import constants from '../constants';
import {mapMutations} from "vuex";

export default {
	i18n: require('../i18n').default,
	mixins: [store(['prices'], function () {
		this.$nextTick(() => (this.restored = true));
	})],
	props: ['advertisementId', 'logDataDefault'],
	data() {
		return ({
			example: constants.example,
			state: 'choosing',
			prices: {},
		});
	},
	computed: {
		price() {
			const {prices: {price = 0} = {}} = this;
			return price;
		}
	},
	methods: {
		...mapMutations({
			autotestLoggerDefaultData: 'others/autotest/autotestLoggerDefaultData',
			autotestLoggerData: 'others/autotest/autotestLoggerData',
		}),
		init() {
			return (
				Object.keys(this.price).length ?
					Promise.resolve(this.price) :
					this.getPrices()
			);
		},
		getPrices() {
			return autotestPriceLaw(this.advertisementId)
				.then((response = {}) => {
					Object.entries(response)
						.forEach(([key, value]) => {
							this.$set(this.prices, key, value);
						});
				});
		},
		onViewExample() {
			this.gaEvent('Сheck_Auto', 'Click_on_link_sample_report_lawyer_new', 'check_selection');
			this.autotestLoggerData({
				...(this.logDataDefault || {}),
				click_action: 1,
			});
		},
		onChoose() {
			this.state = 'checking';

			this.autotestLoggerData({
				...(this.logDataDefault || {}),
				click_action: 2,
				request_type: 2,
				payment_summ: this.price || 0
			});

			this.$emit('choosed', {
				advertisementId: this.advertisementId,
				payload: {
					price: this.price,
					type: 'juristical'
				}
			});
		}
	},
	serverPrefetch() {
		return this.init();
	},
	mounted() {
		this.init();
		this.autotestLoggerDefaultData({
			autotest_juristical_verification: 1,
			autotest_price_juristical_verification: this.price,
		});
	}
};
