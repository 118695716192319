export default {
	messages: {
		ru: {
			'Введите Ваш комментарий': 'Введите Ваш комментарий к заказу',
			'Я согласен': 'Я согласен(а) с',
			'пользовательским соглашением': 'пользовательским соглашением',
			'С вами свяжется менеджер': 'С вами свяжется менеджер сайта для подтверждения проверки',
			'Заказать': 'Заказать',
			'Оплата услуги проходит через сайт AUTO.RIA': 'Оплата услуги проходит через сайт AUTO.RIA',
			'Спасибо': 'Спасибо! Ваша заявка проверки VIN-кода принята',
			'В случае отказа владельца от проверки': 'В случае отказа владельца от проверки, оплаченные средства возвращаются заказчику услуги, за исключением 7% от стоимости заказанной проверки',
			'В ближайшее время': 'В ближайшее время с Вами свяжется менеджер AUTO.RIA для уточнения деталей',
			'В случае отказа владельца, возможность бесплатной проверки возвращается заказчику услуги': 'В случае отказа владельца, возможность бесплатной проверки возвращается заказчику услуги',
		},
		uk: {
			'Введите Ваш комментарий': 'Введіть Ваш коментар до замовлення',
			'Я согласен': 'Я згоден(а) з',
			'пользовательским соглашением': 'угодою користувача',
			'С вами свяжется менеджер': 'З вами зв\'яжеться менеджер сайту для підтвердження перевірки',
			'Заказать': 'Замовити',
			'Оплата услуги проходит через сайт AUTO.RIA': 'Оплата послуги проходить через сайт AUTO.RIA',
			'Спасибо': 'Дякуємо! Ваша заявка на перевірку VIN-коду прийнята',
			'В случае отказа владельца от проверки': 'У разі відмови власника від перевірки, сплачені кошти повертаються замовнику послуги, за винятком 7% від вартості замовленої перевірки',
			'В ближайшее время': 'Найближчим часом з Вами зв’яжеться менеджер AUTO.RIA для уточнення деталей',
			'В случае отказа владельца, возможность бесплатной проверки возвращается заказчику услуги': 'У разі відмови власника, можливість безкоштовної перевірки повертається замовнику послуги',
		},
	}
};
