import { mapActions, mapGetters, mapMutations } from 'vuex';
import humanize from '../../../mixins/humanize';
import Order from '../_Order/index.vue';
import OrderComment from '../_OrderComment/index.vue';
import Acknowledgment from '../_Acknowledgment/index.vue';
import i18n from './i18n';
import { get } from '../../../helpers/cookie';

// Ідентифікатор звіту 'Виїзна перевірка'
const EXAMPLE_REPORT_ID = 169813;

export default {
  name: 'OutsiteInspection',
  props: ['advertisementId', 'id'],
  mixins: [humanize],
  components: {
    Order,
    OrderComment,
    Acknowledgment,
  },
  i18n,
  data() {
    return {
      orderState: 'choosing',
      comment: '',
      ordersId: 0,
      hasOutsiteInspectionCookie: true,
      // logDataDefault: {
      // action_block: 5,
      // block_type: 5,
      // },
    };
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      advertisements: 'graphql/advertisements',
      userData: 'Common/userData',
      outsiteAutotest: 'others/autotest/outsiteAutotest',
    }),
    advertisement() {
      return this.advertisements(this.advertisementId);
    },
    price() {
      // TODO: this service is temporary free
      // return this.outsiteAutotest.reduce((res, item) => res + item.price, 0);
      return 0;
    },
    humanizedPrice() {
      return this.price === 0 ? this.$t('Безкоштовно') : `${this.humanizeNumber(this.price)} грн`;
    },
    showBlock() {
      return Boolean(
        this.advertisement?.category?.id === '1'
        && this.outsiteAutotest.length
        && this.hasOutsiteInspectionCookie,
      );
    },
    orderParams() {
      const {
        userId = '',
        ownerName: name = '',
        userEmail: email = '',
        userPhones = [],
      } = this.userData;

      return {
        advertisementId: this.advertisementId,
        user: {
          id: userId,
          name,
          email,
          phone: userPhones[0] || '',
        },
        orderData: {
          technical: {
            // TODO: temporary only for Vinnytsia
            cityId: 1,
            stoId: 705,
            packageId: 44,
            sto: {
              stoId: 705,
            },
            price: this.price,
            type: 'technical',
            verifications: ['7', '9', '10', '11', '12', '13'],
          },
        },
        message: this.comment,
      };
    },
    exampleReportLink() {
      return `${this.langPrefix}/vue/technicalcheck/${EXAMPLE_REPORT_ID}`;
    },
  },
  methods: {
    ...mapActions({
      getOutsiteAutotest: 'others/autotest/getOutsiteAutotest',
    }),
    ...mapMutations({
      autotestLoggerDefaultData: 'others/autotest/autotestLoggerDefaultData',
      autotestLoggerData: 'others/autotest/autotestLoggerData',
    }),
    viewExample() {
      // this.autotestLoggerData({ ...this.logDataDefault, click_action: 1 });
    },
    buyCheck() {
      this.orderState = 'commenting';
      // this.autotestLoggerData({
      //   ...this.logDataDefault,
      //   click_action: 2,
      //   request_type: 2,
      //   payment_summ: this.price,
      //   initial_payment: this.profit,
      // });
    },
    onCommented(payload = {}) {
      const { user, comment } = payload;
      this.user = user;
      this.comment = comment;
      this.orderState = 'ordering';
      // this.sendOrderPriceGTM(this.offer?.payload?.price || 0);
    },
    onOrdered(payload) {
      this.orderResponse = { ...payload };

      const {
        orders: {
          technical: {
            id: techId = 0,
          } = {},
        } = {},
      } = payload;

      // this.autotestLoggerData({
      //   ...this.logDataDefault,
      //   billing_order_id: techBillingOrderId,
      //   order_spare_id: techId,
      //   payment_summ: this.offer?.payload?.price || 0,
      //   request_type: 4,
      // });

      this.comment = '';
      this.ordersId = techId;
      this.orderState = 'ordered';

      // this.gaEvent('Сheck_Auto', 'Click_on_Button_order_technical_1_new', 'check_selection');
    },
  },
  serverPrefetch() {
    const {
      year,
      brand: {
        id: brandId,
      } = {},
    } = this.advertisement || {};

    return this.getOutsiteAutotest({
      stoId: 707,
      brandId,
      year,
    });
  },
  mounted() {
    this.hasOutsiteInspectionCookie = get('outsiteInspection');

    // this.autotestLoggerDefaultData({
    //   autotest_insurance_verifications: 1,
    //   autotest_price_insurance_verifications: this.price || 0,
    // });
  },
};
