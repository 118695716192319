export default {
	messages: {
		ru: {
			'с Вами свяжется менеджер': 'В ближайшее время с Вами свяжется менеджер AUTO.RIA для уточнения деталей',
			'принята': 'принята',
			'Спасибо': 'Спасибо',
		},
		uk: {
			'с Вами свяжется менеджер': 'Найближчим часом з Вами зв’яжеться менеджер AUTO.RIA для уточнення деталей',
			'принята': 'прийнята',
			'Спасибо': 'Дякую',
		},
	}
};
