module.exports = (pairs, cb = new Function()) => {
	pairs =
		(Array.isArray(pairs) ? pairs : [pairs])
			.filter((item) => item)
			.map((pair) =>
				typeof pair === 'string' ?
					({attribute: pair, property: pair}) :
					({attribute: pair.attribute, property: pair.property || pair.attribute}));

	return ({
		beforeMount() {
			pairs.forEach(
				({attribute, property} = {}) => {
					if (this.$el) {
						this[property] = JSON.parse(this.$el.getAttribute(attribute));
					}
				}
			);
			cb.call(this, pairs);
		},
	});
};
