export default {
	messages: {
		ru: {
			'Диагностика технического состояния автомобиля на СТО партнерах': 'Диагностика технического состояния автомобиля на СТО партнерах',
			'Техническая проверка в Сертифицированном': 'Техническая проверка в Сертифицированном',
			'центре': 'центре',
			'техническую проверку': 'техническую проверку',
			'Лишились питання й хочете дізнатись більше про технічну перевірку?': 'Остались вопросы и хотите узнать больше о технической проверке?',
			'Дізнатись більше': 'Узнать больше',
			'электроавто': 'электроавто',
			'Техническая проверка': 'Техническая проверка',
			'в Сертифицированном': 'в Сертифицированном',
		},
		uk: {
			'Диагностика технического состояния автомобиля на СТО партнерах': 'Діагностика технічного стану автомобіля на СТО партнерах',
			'Техническая проверка в Сертифицированном': 'Технічна перевірка в Сертифікованому',
			'центре': 'центрі',
			'техническую проверку': 'технічну перевірку',
			'электроавто': 'електроавто',
			'Техническая проверка': 'Технічна перевірка',
			'в Сертифицированном': 'в Сертифікованому',
			'Лишились питання й хочете дізнатись більше про технічну перевірку?': 'Лишились питання й хочете дізнатись більше про технічну перевірку?',
			'Дізнатись більше': 'Дізнатись більше',
		},
	}
};
