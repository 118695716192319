export default {
	messages: {
		ru: {
			'Перевірка по страховим базам': 'Проверка по страховым базам',
			'Дані провідних страхових компаній України': 'Данные ведущих страховых компаний Украины',
			'Вид звіту': 'Вид отчета',
			'Приклад': 'Пример',
			'звіту': 'отчета',
			'Історія всіх страхових випадків (КАСКО, ОСЦПВ)': 'История всех страховых случаев (КАСКО, ОСАГО)',
			'Кількість власників': 'Количество владельцев',
			'Зміст рішення страховиків': 'Содержание решения страховщиков',
			'Суми й дати виплат чи відмови від відшкодування збитків': 'Суммы и выплаты или отказа от возмещения убытков',
			'Всього до сплати': 'Всего к оплате',
			'Замовити перевірку': 'Заказать проверку',
			'по страховим базам': 'по страховым базам',
            'Перевірка страхових випадків': 'Проверка страховых случаев, связанных с автомобилем, по базам ведущих страховых компаний Украины',
		},
		uk: {
			'Перевірка по страховим базам': 'Перевірка по страховим базам',
			'Дані провідних страхових компаній України': 'Дані провідних страхових компаній України',
			'Вид звіту': 'Вид звіту',
			'Приклад': 'Приклад',
			'звіту': 'звіту',
			'Історія всіх страхових випадків (КАСКО, ОСЦПВ)': 'Історія всіх страхових випадків (КАСКО, ОСЦПВ)',
			'Кількість власників': 'Кількість власників',
			'Зміст рішення страховиків': 'Зміст рішення страховиків',
			'Суми й дати виплат чи відмови від відшкодування збитків': 'Суми й дати виплат чи відмови від відшкодування збитків',
			'Всього до сплати': 'Всього до сплати',
			'Замовити перевірку': 'Замовити перевірку',
			'по страховим базам': 'по страховим базам',
            'Перевірка страхових випадків': 'Перевірка страхових випадків, пов’язаних з авто, за базами провідних страхових компаній України',
		},
	}
};
